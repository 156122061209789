import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { Card, Collapse } from "antd"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Img from "gatsby-image"
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons"

import IconSafeType from "../../images/icono-cripto-resguardo.svg"
import IconCreditType from "../../images/icono-cripto-creditos.svg"
import IconExchangeType from "../../images/icono-cripto-exchange.svg"
import IconInvestmentType from "../../images/icono-cripto-inversion.svg"
import Quotations from "../../images/quotations.svg"

import "./about-us.scss"
import { darkGrayColor } from "../../theme/colors.scss"

const AboutUs = ({ intl, location }) => {
  const data = useStaticQuery(graphql`
    query {
      laptop: file(relativePath: { eq: "placeholder-video-img.png" }) {
        childImageSharp {
          fluid(maxHeight: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aboutUsFaq1TitlePC: file(
        relativePath: { eq: "lphant_website_black.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aboutUsFaq1TitleTablet: file(
        relativePath: { eq: "Lphant_website-uno-col1.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1562) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [activeKey, setActiveKey] = useState()
  const menuSelector = location?.state?.menuSelector || 1
  return (
    <Layout prefooter="1" menuSelector={menuSelector}>
      <SEO title="LoanCalculator" />
      <div className="about-us">
        <div className="how-we-are-header">
          <h1 className="how-we-are-title">
            {intl.formatMessage({ id: "about-us-title" })}
          </h1>
          <h1 className="how-we-are-message">
            {intl.formatMessage({ id: "about-us-message" })}
          </h1>
        </div>
        <div className="background-gray">
          <div className="content-wrapper-1">
            <h1 className="content-wrapper-1-title">
              {intl.formatMessage({ id: "about-us-wrapper-title" })}
            </h1>
            <h1 className="content-wrapper-1-subtitle">
              {intl.formatMessage({ id: "about-us-wrapper-slog" })}
            </h1>
          </div>
          <div className="content-wrapper-2">
            <h1 className="content-wrapper-2-title">
              {intl.formatMessage({ id: "about-us-wrapper-message-1" })}
            </h1>
            <h1 className="content-wrapper-2-title">
              {intl.formatMessage({ id: "about-us-wrapper-message-2" })}
            </h1>
          </div>
        </div>

        <div className="background-white">
          <div className="content-wrapper-1">
            <h1 className="content-wrapper-1-title">
              <Quotations />
              {intl.formatMessage({ id: "about-us-cards-title" })}
            </h1>
          </div>
          <div className="content-wrapper-2">
            <Img
              className="image-right"
              fluid={data.laptop.childImageSharp.fluid}
            />
          </div>
        </div>
        <div className="background-white-2">
          {intl.formatMessage({ id: "about-us-cards-description" })}
        </div>

        <div className="content-wrapper row center">
          <Card className="col spacer center">
            <IconSafeType />
            <h3>{intl.formatMessage({ id: "about-us-card-1-title" })}</h3>
            <p>{intl.formatMessage({ id: "about-us-card-1-description" })}</p>
          </Card>
          <Card className="col spacer center">
            <IconCreditType />
            <h3>{intl.formatMessage({ id: "about-us-card-2-title" })}</h3>
            <p>{intl.formatMessage({ id: "about-us-card-2-description" })}</p>
          </Card>
          <Card className="col spacer center">
            <IconExchangeType />
            <h3>{intl.formatMessage({ id: "about-us-card-3-title" })}</h3>
            <p>{intl.formatMessage({ id: "about-us-card-3-description" })}</p>
            <p
              className="primary-color"
              style={{ fontFamily: "Cairo-Bold", fontSize: "16px" }}
            >
              {intl.formatMessage({ id: "about-us-card-3-description-2" })}
            </p>
          </Card>
          <Card className="col spacer center">
            <IconInvestmentType />
            <h3>{intl.formatMessage({ id: "about-us-card-4-title" })}</h3>
            <p>{intl.formatMessage({ id: "about-us-card-4-description" })}</p>
            <p
              className="primary-color"
              style={{ fontFamily: "Cairo-Bold", fontSize: "16px" }}
            >
              {intl.formatMessage({ id: "about-us-card-4-description-2" })}
            </p>
          </Card>
        </div>
        <div className="faqs-content">
          <Collapse
            expandIcon={({ isActive }) =>
              isActive ? (
                <MinusCircleFilled
                  style={{
                    color: darkGrayColor,
                    fontSize: "30px",
                    opacity: "0.5",
                  }}
                />
              ) : (
                <PlusCircleFilled
                  style={{ color: darkGrayColor, fontSize: "30px" }}
                />
              )
            }
            accordion
            ghost
            bordered={false}
            expandIconPosition="left"
            onChange={key => {
              setActiveKey(key)
            }}
          >
            <Collapse.Panel
              key={intl.formatMessage({ id: "about-us-faq-1-title" })}
              header={
                <p
                  className={
                    activeKey ===
                    intl.formatMessage({ id: "about-us-faq-1-title" })
                      ? "panel-header selected"
                      : "panel-header"
                  }
                >
                  {intl.formatMessage({ id: "about-us-faq-1-title" })}
                </p>
              }
            >
              <p className="panel-message">
                {intl.formatMessage({ id: "about-us-faq-1-description" })}
              </p>
              <div className="pc">
                <Img fluid={data.aboutUsFaq1TitlePC.childImageSharp.fluid} />
              </div>
              <div className="tablet">
                <Img
                  fluid={data.aboutUsFaq1TitleTablet.childImageSharp.fluid}
                />
              </div>
            </Collapse.Panel>
            <Collapse.Panel
              key={intl.formatMessage({ id: "about-us-faq-2-title" })}
              header={
                <p
                  className={
                    activeKey ===
                    intl.formatMessage({ id: "about-us-faq-2-title" })
                      ? "panel-header selected"
                      : "panel-header"
                  }
                >
                  {intl.formatMessage({ id: "about-us-faq-2-title" })}
                </p>
              }
            >
              <p className="panel-message">
                {intl.formatMessage({ id: "about-us-faq-2-description" })}
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              key={intl.formatMessage({ id: "about-us-faq-3-title" })}
              header={
                <p
                  className={
                    activeKey ===
                    intl.formatMessage({ id: "about-us-faq-3-title" })
                      ? "panel-header selected"
                      : "panel-header"
                  }
                >
                  {intl.formatMessage({ id: "about-us-faq-3-title" })}
                </p>
              }
            >
              <p className="panel-message">
                {intl.formatMessage({ id: "about-us-faq-3-description" })}
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              key={intl.formatMessage({ id: "about-us-faq-4-title" })}
              header={
                <p
                  className={
                    activeKey ===
                    intl.formatMessage({ id: "about-us-faq-4-title" })
                      ? "panel-header selected"
                      : "panel-header"
                  }
                >
                  {intl.formatMessage({ id: "about-us-faq-4-title" })}
                </p>
              }
            >
              <p className="panel-message">
                {intl.formatMessage({ id: "about-us-faq-4-description" })}
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              key={intl.formatMessage({ id: "about-us-faq-5-title" })}
              header={
                <p
                  className={
                    activeKey ===
                    intl.formatMessage({ id: "about-us-faq-5-title" })
                      ? "panel-header selected"
                      : "panel-header"
                  }
                >
                  {intl.formatMessage({ id: "about-us-faq-5-title" })}
                </p>
              }
            >
              <p className="panel-message">
                {intl.formatMessage({ id: "about-us-faq-5-description" })}
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              key={intl.formatMessage({ id: "about-us-faq-6-title" })}
              header={
                <p
                  className={
                    activeKey ===
                    intl.formatMessage({ id: "about-us-faq-6-title" })
                      ? "panel-header selected"
                      : "panel-header"
                  }
                >
                  {intl.formatMessage({ id: "about-us-faq-6-title" })}
                </p>
              }
            >
              <p className="panel-message">
                {intl.formatMessage({ id: "about-us-faq-6-description" })}
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              key={intl.formatMessage({ id: "about-us-faq-7-title" })}
              header={
                <p
                  className={
                    activeKey ===
                    intl.formatMessage({ id: "about-us-faq-7-title" })
                      ? "panel-header selected"
                      : "panel-header"
                  }
                >
                  {intl.formatMessage({ id: "about-us-faq-7-title" })}
                </p>
              }
            >
              <p className="panel-message">
                {intl.formatMessage({ id: "about-us-faq-7-description" })}
              </p>
            </Collapse.Panel>
          </Collapse>
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(AboutUs)
